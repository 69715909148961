import React from "react";
import { FaEnvelope, FaFacebook, FaLinkedin } from "react-icons/fa";
import styles from "../css/card.module.css"; 

function Card({ data }){
    console.log(data);
  const { Pic, Name, Post, Dept, Contact, Email, Facebook, LinkedIn, Instagram } = data;

  return (
    <div className={styles.organizercard}>
      <img src={Pic.url} alt={Name} className={styles.organizerimage} />
      <div className={styles.organizerdetails}>
        <div className={styles.h3}><h3>{Name}</h3></div>
        <h4>{Post}</h4>
        <h4>{Dept}</h4>
        <h4>{Contact}</h4>
        <div className={styles.organizersociallinks}>
          <a href={`mailto:${Email}`} className={styles.sociallink}><FaEnvelope /></a>
          <a href={Facebook} className={styles.sociallink} target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href={LinkedIn} className={styles.sociallink} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        </div>
      </div>
    </div>
  );
};

export default Card;

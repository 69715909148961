import React from 'react';
import logo from '../src/Centre_Text.png';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import Navbar from './components/navbar';
import CoreTeam from './pages/CoreTeam';
import Gallery from './pages/Gallery';
import Query from './pages/Query';
// import Queries from './pages/Queries';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <div className="App">
      <Navbar />
      {location.pathname === '/' && (
        <div className='insider'>
          <img src={logo} className="App-logo" alt="logo" />
          <TypeAnimation
            sequence={[
              'C', 500, 'Co', 500, 'Com', 500, 'Comi', 500, 'Comin', 500,
              'Coming', 500, 'Coming ', 500, 'Coming S', 500, 'Coming So', 500,
              'Coming Soo', 500, 'Coming Soon', 500, 'Coming Soon.', 500, 'Coming Soon..', 500,
              'Coming Soon...', 500, 'Coming Soon....', 5000,
              () => console.log('Done typing!')
            ]}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            speed={100}
            style={{
              marginTop: '-8px',
              fontSize: '3em',
              color: 'white',
              textShadow: '1px 1px #000000',
              fontFamily: 'Dancing Script',
            }}
          />
          {/* <a href="https://events.antaragni.in" className="button1" target='_blank' rel="noreferrer">Register</a> */}
        </div>
      )}

      <Routes>
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/coreTeam" element={<CoreTeam />} />
        <Route path="/queries" element={<Query/>} />
      </Routes>
    </div>
  );
}

export default App;

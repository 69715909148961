import { useState, useEffect, useRef } from "react";
import styles from "../css/gallery.module.css";
import Loader from "../components/loader";
import { getSingleDoc } from "../config/firebaseFirestore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import myImage1 from "../gallery_cropped.png";

const Gallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [totalImages, setTotalImages] = useState([])
  const [loading, setLoading] = useState(true);
  const observer = useRef();

  const fetchGalleryImages = async () => {
    try {
      let data = await getSingleDoc("WebContents", "gallery");
      if (data !== null) {
        const sortedImages = data.data.sort((a, b) => b.like - a.like);
        setTotalImages(sortedImages.slice(0, 39));
        loadMoreImages();
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loadMoreImages = () => {
    setGalleryImages((prevImages) => [...prevImages, ...totalImages.slice(galleryImages.length % 39, (galleryImages.length % 39) + 20)]);
  };

  useEffect(() => {
    fetchGalleryImages();
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreImages();
      }
    }, {
      threshold: 1
    });
    const currentObserver = observer.current;
    const target = document.querySelector("#end");
    if (target) {
      currentObserver.observe(target);
    }
    return () => {
      if (target) {
        currentObserver.unobserve(target);
      }
    };
  }, [galleryImages]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.gallery}>
      <img src={myImage1} alt="Gallery" className={styles.gallery_heading}/>
      <div className={styles.galleryWrap}>
        {galleryImages.map((slide, index) => (
          <div className={styles.single} key={index}>
            <img src={slide.img.url} alt="" />
          </div>
        ))}
        {Array.from({length: 3}).map((slide, index) => {
          return (
            <div className={`${styles.single} ${styles.empty}`} id="end"></div>
          )
        })

        }
      </div>
    </div>
  );
};

export default Gallery;
